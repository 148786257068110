@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'), url(./fonts/RobotoMono-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

h1 {
  font-family: 'Roboto Mono';
  font-weight: 400;
}

body {
  text-align: justify;
  padding: 5%;
}

p {
  margin-top: 27px;
}

.main-text {
  font-family: 'Roboto Mono';
}

.video-player {
  width: 30vw;
}

@media (min-width: 768px) {
  body {
    width: 760px; 
    margin: 0 auto;
  }
}